<template>
  <div class="mb-20 mt-30 container paragraph--margin">
    <div class="bg-primary-50 rounded">
      <h3
        v-blokkli-editable:field_title
        class="text-primary-700 text-xs font-bold p-20 mb-20"
      >
        {{ title }}
      </h3>
      <div class="px-20 lg:px-30 xl:px-50 pb-30">
        <figure class="flex flex-col md:flex-row mb-20 md:gap-20 items-center">
          <blockquote>
            <p
              v-blokkli-editable:field_quote
              class="italic after:text-primary-700 before:text-primary-700 font-medium md:text-2xl md:mb-0 mb-20 text-xl before:content-['«'] after:content-['»'] mobile-only:hyphens-auto text-pretty"
            >
              {{ quote }}
            </p>
          </blockquote>

          <div
            v-if="image"
            class="size-140 rounded-full overflow-hidden shrink-0 ml-auto mt-10 md:mt-0"
          >
            <FieldItemTypeImage
              v-if="image"
              v-bind="image.image"
              :config="imageStyle"
              img-class="w-full"
            />
          </div>
        </figure>

        <figcaption class="sm:flex sm:gap-10 sm:justify-end text-end text-xs">
          <strong
            v-blokkli-editable:field_name
            class="block sm:inline text-nowrap"
            >{{ name }}</strong
          >
          <span v-blokkli-editable:field_role class="text-gray-600">
            {{ role }}
          </span>
        </figcaption>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { ParagraphQuoteFragment } from '#graphql-operations'

defineBlokkli({
  bundle: 'quote',
  editor: {
    previewWidth: 600,
    editTitle: (el) => el.querySelector('figcaption')?.innerText,
  },
})

defineProps<{
  title?: ParagraphQuoteFragment['title']
  name?: ParagraphQuoteFragment['name']
  quote?: ParagraphQuoteFragment['quote']
  role?: ParagraphQuoteFragment['role']
  image?: ParagraphQuoteFragment['image']
}>()

const imageStyle = defineImageStyle({
  type: 'sizes',
  aspectRatio: 1,
  sizes: {
    sm: 140,
  },
})

defineOptions({
  name: 'ParagraphQuote',
})
</script>
